import cn from 'classnames';
import React, { useMemo } from 'react';

import { ERROR } from 'endpoints/endpoint';
import { SubmitError } from 'client/types';
import { useAppSelector } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';

import { Modal } from '../Modal';
import { Button } from '../Button';

import css from './FormSubmitErrorModal.module.scss';

interface Props {
  className?: string;
  errorCode?: SubmitError;
  isOpen: boolean;
  onClose: () => void;
}

export const FormSubmitErrorModal: React.FC<Props> = (props) => {
  const { className = '', isOpen, onClose, errorCode } = props;
  const formInfo = useAppSelector(selectFormInfo);
  const confirmBtnLabel = 'סגירה';
  const somethingWentWrong = 'משהו השתבש';
  const messagesByErrorCode = useMemo(
    () => ({
      [ERROR.LEAD_ALREADY_EXIST]: formInfo?.formContent.attemptPlayTwiceText || 'אתה כבר מלא טופס זה',
      [ERROR.FORM_IS_NOT_ACTIVE]: 'סליחה, נראה כאילו הטופס כבר לא פעיל',
    }),
    [formInfo],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentClassName={cn(css.formSubmitErrorModal, className)}>
      {errorCode && <div className={css.message}>{messagesByErrorCode[errorCode] || somethingWentWrong}</div>}
      <Button onClick={onClose} className={css['submit-btn']}>
        {confirmBtnLabel}
      </Button>
    </Modal>
  );
};

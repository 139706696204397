import React, { useState, useRef } from 'react';
import cn from 'classnames';

import { SHARE_CONSTANTS } from 'client/constants';
import useOnClickOutside from 'client/hooks/click-outside';
import { ShareType, share } from 'client/utils/share';
import { useResponsive } from 'client/hooks';
import { analytics } from 'client/utils';

import Icon from '../Icon';

import css from './Socials.module.scss';

type Props = {
  className?: string;
  minified?: boolean;
  formTitle?: string;
};

const Socials: React.FC<Props> = ({ className, minified, formTitle = '' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDesktop] = useResponsive('DESKTOP');
  const socialsRef = useRef(null);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const onShare = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.dataset.id as ShareType;

    if (type) {
      // analytics.gtag.event('content_share', { social_network_name: type, page_title: formTitle });
      analytics.gtm.event('content_share', { social_network_name: type, page_title: formTitle });

      share(type, isDesktop);
    }
  };

  useOnClickOutside<HTMLDivElement>(socialsRef, closeMenu);

  return (
    <div className={cn(css.socials, isOpen && css.open, minified && css.minified, className)} ref={socialsRef}>
      <div className={css.overflowWrap}>
        <button type="button" className={css.socialButton} data-id={SHARE_CONSTANTS.WHATSAPP} onClick={onShare}>
          <Icon type="whatsapp" width={32} height={32} className={css.iconWhatsapp} />
        </button>
        <button className={css.socialButton} type="button" data-id={SHARE_CONSTANTS.FACEBOOK} onClick={onShare}>
          <Icon type="facebook" width={32} height={32} className={css.iconFacebook} />
        </button>
      </div>
      {minified && (
        <div className={`${css.toggleBtn} ${isOpen ? css.open : ''}`} onClick={toggleOpenMenu}>
          <Icon type="share" width={32} height={32} className={`${css.iconToggle} `} />
        </div>
      )}
    </div>
  );
};

export default Socials;

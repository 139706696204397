import React, { useState } from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import _ from 'lodash';

import { ClientFieldItem } from 'models/form/interface';

import { ErrorLabel } from '../ErrorLabel';

import css from './Select.module.scss';

interface Props {
  className?: string;
  fieldInfo: ClientFieldItem;
  name: string;
  options?: Array<{ label: string; value: string }>;
}

const DropdownIndicator = () => (
  <div className={css.dropdownIndicator}>
    <span />
  </div>
);

const IndicatorSeparator = () => null;

const Select: React.FC<Props> = (props) => {
  const { className = '', name, options = [], fieldInfo } = props;
  const [field, meta, helpers] = useField(name);
  const errorMsg = meta.error?.[0] ?? '';
  const selectValue = options.find((opt) => opt.value === field.value) ?? '';
  const isRequired = _.get(fieldInfo, 'validation.required', false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event: any) => {
    helpers.setValue(event.value);
    setIsFocused(true);
  };

  const fieldProps = {
    ...field,
    onChange: (event: any) => {
      handleChange(event);
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onReset: () => {
      console.info('reset');
    },
    onBlur: () => {
      if (!field.value) {
        setIsFocused(false);
      }
    },
  };

  return (
    <div className={cn(css.select, meta.touched && meta.error?.[0] && css.withError, className)}>
      <div className={cn(css.selectWrapper, isFocused && css.focused)}>
        <p className={css.label}>{isRequired ? `*${fieldInfo.label}` : fieldInfo.label}</p>
        <ReactSelect
          isRtl
          {...fieldProps}
          inputId={field.name}
          value={selectValue}
          onChange={handleChange}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={typeof window !== 'undefined' ? document.body : undefined}
          options={options}
          placeholder=""
          isSearchable={false}
          components={{ DropdownIndicator, IndicatorSeparator }}
          className="rc-container"
          classNamePrefix="rc"
        />
      </div>

      {errorMsg && meta.touched && <ErrorLabel text={errorMsg} />}
    </div>
  );
};

export default Select;

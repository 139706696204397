import { Types } from 'mongoose';

import { FIELD_INPUT_TYPE, FIELD_WIDTH, FIELD_TYPE, VALIDATION_TYPE } from '../inputField/interface';

type FormGeneralDB = {
  name: string;
  slug: string;
  gaID?: string;
  metaTitle: string;
  metaDescription: string;
  shareTitle: string;
  shareDescription: string;
  shareImg?: string;
  isShareIconsVisible?: boolean;
  pixels?: string;
  isRegisterOncePerUser?: boolean;
  oncePerUserBy?: `${ONCE_PER_USER_OPTIONS}`;
  isUseActiveTrail?: boolean;
  activeTrailGroup?: string;
};

export enum ONCE_PER_USER_OPTIONS {
  EMAIL = 'email',
  PHONE = 'mobilePhone',
  HOME_PHONE = 'homePhone',
  PERSON_ID = 'personID',
}

export enum REGULAR_FIELD_NAMES {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  MOBILE_PHONE = 'mobilePhone',
  HOME_PHONE = 'homePhone',
  ID = 'personID',
  CITY = 'city',
  STREET = 'street',
  HOUSE_NUMBER = 'houseNumber',
  APARTMENT_NUMBER = 'apartmentNumber',
  FLOOR = 'floor',
  ENTRANCE = 'entrance',
  DELIVERY_INSTRUCTIONS = 'deliveryInstructions',
  SUBSCRIPTION = 'subscription',
  DISCOUNT_CODE = 'discountCode',
  SALE_NAME = 'saleName',
}

export type FormGeneral = Omit<FormGeneralDB, 'shareImg'> & {
  shareImg?: File | string;
};

type FormDesignDB = {
  desktopBg: string;
  mobileBg: string;
  logo1?: string;
  logo1Link?: string;
  logo2?: string;
  logo2Link?: string;
  sendButtonColor?: string;
  pageBackgroundColor?: string;
  formTitleTextColor?: string;
  linkTextColor?: string;
  fieldTextColor?: string;
};

export type FormDesign = Omit<FormDesignDB, 'desktopBg' | 'mobileBg' | 'logo1' | 'logo2'> & {
  desktopBg?: File | string;
  mobileBg?: File | string;
  logo1?: File | string;
  logo2?: File | string;
};

type FormContentDB = {
  formTitle?: string;
  formSubTitle?: string;
  sendButtonText?: string;
  successfulRegistrationText?: string;
  completedActivityText?: string;
  attemptPlayTwiceText?: string;
};

export type FormContent = FormContentDB;

type FormOtherDB = {
  isShowTerms?: boolean;
  termsFile?: string;
  privacyFile?: string;
  conditionsLink?: string;
  acceptText?: string;
  isDisplayCallButton?: boolean;
  callButtonPhone?: string;
  callButtonText?: string;
};

export type FormOther = Omit<FormOtherDB, 'termsFile' | 'conditionsLink' | 'privacyFile'> & {
  termsFile?: File | string;
  conditionsLink?: File | string;
  privacyFile?: File | string;
};

export type ValidationData = {
  [VALIDATION_TYPE.REQUIRED]: boolean;
  [VALIDATION_TYPE.CHARACTERS_LENGTH]?: string;
  [VALIDATION_TYPE.MAX_LENGTH]?: string;
  [VALIDATION_TYPE.MIN_LENGTH]?: string;
  [VALIDATION_TYPE.EMAIL]?: boolean;
  [VALIDATION_TYPE.HOME_PHONE]?: boolean;
  [VALIDATION_TYPE.MOBILE_PHONE]?: boolean;
  [VALIDATION_TYPE.IS_PAST_DATE_NOT_SELECTABLE]?: boolean;
  [VALIDATION_TYPE.ONLY_NUMBERS]?: boolean;
  [VALIDATION_TYPE.ONLY_HEBREW]?: boolean;
};

export type ValidationMessages = {
  [P in keyof ValidationData]: string;
};

export type FieldItem = {
  id: Types.ObjectId | string;
  inputId: Types.ObjectId;
  label: string;
  name: string;
  options?: Array<{
    value: string;
    label: string;
    isVisible: boolean;
  }>;
  type: FIELD_TYPE;
  inputType: FIELD_INPUT_TYPE;
  isVisible: boolean;
  other: {
    width: FIELD_WIDTH;
  };
  validation: ValidationData;
  validationMessages: ValidationMessages;
};

export interface IModel {
  _id: Types.ObjectId;
  general: FormGeneralDB;
  design: FormDesignDB;
  formContent: FormContentDB;
  fields: Array<FieldItem>;
  other: FormOtherDB;
  adminId: Types.ObjectId;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export type ClientFieldItem = Omit<FieldItem, 'id' | 'inputId'> & {
  id: string;
  inputId: string;
};
export type ClientModel = Omit<IModel, '_id' | 'adminId' | 'fields' | 'createdAt'> & {
  _id: string;
  adminId: string;
  fields: Array<ClientFieldItem>;
};

import { Types } from 'mongoose';

export enum FIELD_TYPE {
  REGULAR = 'regular',
  TEXT_FIELD = 'textField',
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
  DATEPICKER = 'datepicker',
  LONG_TEXT_FIELD = 'longTextField',
}

export enum VALIDATION_TYPE {
  REQUIRED = 'required',
  CHARACTERS_LENGTH = 'charactersLength',
  IS_PAST_DATE_NOT_SELECTABLE = 'isPastDateNotSelectable',
  EMAIL = 'email',
  MOBILE_PHONE = 'mobilePhone',
  HOME_PHONE = 'homePhone',
  ONLY_HEBREW = 'onlyHebrewCharacters',
  ONLY_NUMBERS = 'onlyNumbers',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
}

export enum FIELD_INPUT_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  BOOLEAN = 'boolean',
}

export enum FIELD_WIDTH {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export interface IModel {
  _id: Types.ObjectId;
  type: FIELD_TYPE;
  inputType: FIELD_INPUT_TYPE;
  label: string;
  name: string;
  options?: Array<{
    value: string;
    label: string;
    isVisible: boolean;
  }>;
  other: { width: FIELD_WIDTH };
  validationTypes: Types.Array<ValidationTypesItem>;
  validationMessages: Types.Array<Omit<ValidationTypesItem, 'value'> & { value: string }>;
}

export type ValidationTypesItem = {
  name: VALIDATION_TYPE;
  value: string | boolean;
  editable: boolean;
};

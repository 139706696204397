import React from 'react';
import cn from 'classnames';

import { useAppSelector, useResponsive } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';

import Socials from '../Socials';
import AccessabilityIcon from '../AccessabilityIcon';

import css from './Header.module.scss';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isMobile] = useResponsive('MOBILE');
  const formInfo = useAppSelector(selectFormInfo);
  const logo1 = formInfo?.design.logo1 ?? '';
  const logo2 = formInfo?.design.logo2 ?? '';
  const logo1Link = formInfo?.design.logo1Link ?? '';
  const logo2Link = formInfo?.design.logo2Link ?? '';

  return (
    <header className={cn(css.header, className)}>
      <div className={css.logos}>
        {logo1 && (
          <a
            href={logo1Link}
            target="_blank"
            rel="noreferrer"
            className={cn(css.logo, css.logoOne, !logo1Link && css.withoutLink)}
          >
            <img src={logo1} alt="Logo one" />
          </a>
        )}
        {logo2 && (
          <a
            href={logo2Link}
            target="_blank"
            rel="noreferrer"
            className={cn(css.logo, css.logoTwo, !logo2Link && css.withoutLink)}
          >
            <img src={logo2} alt="Logo two" />
          </a>
        )}
      </div>
      <div className={css.socialsWrapper}>
        <AccessabilityIcon />
        {formInfo?.general.isShareIconsVisible && (
          <Socials formTitle={formInfo.general.metaTitle} minified={isMobile} />
        )}
      </div>
    </header>
  );
};

export default Header;

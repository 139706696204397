export const analytics = {
  gtag: {
    event: (action: string, params?: Record<string, string>) => {
      // @ts-expect-error gtag is added in html.head:
      const { gtag } = window;

      if (gtag) {
        if (process.env.MODE === 'local') console.log('gtag:', action, params);
        gtag('event', action, params);
      } else {
        // console.log('%cgtag is undefined', 'color: red');
      }
    },
  },
  gtm: {
    event: (action: string, params?: Record<string, unknown>) => {
      // @ts-expect-error dataLayer is added in index.ejs file:
      window.dataLayer.push({
        event: action,
        ...params,
      });
    },
  },
};

import * as yup from 'yup';

import { leads } from 'models/index';
import { LEADS_PAGINATE } from 'routes/api/constants';
import { Params2 as PaginationParams, Result2 as PaginationResult } from 'endpoints/pagination';

import { endpoint, EndpointError } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';
import { FieldItem } from 'models/form/interface';

export type PaginationResultItem = Omit<leads.IModel, 'fields' | '_id'> & {
  fields: Array<FieldItem & { value: string | number | boolean }>;
  formName: string;
  formId: string;
  _id: string;
};

export type PaginateParams = PaginationParams<leads.IModel>;
export type PaginateResult = PaginationResult<PaginationResultItem>;
export type PaginateError = EndpointError;

export const paginateParamsSchema = yup.object().shape({
  limit: yup.number().min(1).required(VALIDATION_ERROR.REQUIRED),
  offset: yup.number().min(0).required(VALIDATION_ERROR.REQUIRED),
});

export const paginate = endpoint<PaginateParams, PaginateResult, PaginateError>({
  method: 'GET',
  url: () => LEADS_PAGINATE,
});

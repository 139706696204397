import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useAppSelector } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';
import { analytics } from 'client/utils';
import Icon from '../Icon';

import css from './CallButton.module.scss';

interface Props {
  className?: string;
}

export const CallButton: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const formInfo = useAppSelector(selectFormInfo);

  const callButtonPhone = _.get(formInfo, 'other.callButtonPhone', '');
  const callButtonText = _.get(formInfo, 'other.callButtonText', '');
  const shapeColor = _.get(formInfo, 'design.sendButtonColor', '#000');

  const clickOnButton = () => {
    // analytics.gtag.event('click_to_dial', { page_title: formInfo?.general.metaTitle ?? '' });
    analytics.gtm.event('click_to_dial', { page_title: formInfo?.general.metaTitle ?? '' });
  };
  return (
    <a href={`tel:${callButtonPhone}`} onClick={clickOnButton} className={classNames(css.callButton, className)}>
      <Icon type="phone" className={css.phoneIcon} shapeColor={shapeColor} />
      {callButtonText}
    </a>
  );
};

import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useAppSelector, useResponsive } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';

import css from './TextsSection.module.scss';

interface Props {
  className?: string;
}

const TextsSection: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const formInfo = useAppSelector(selectFormInfo);

  const title = _.get(formInfo, 'formContent.formTitle', '');
  const subTitle = _.get(formInfo, 'formContent.formSubTitle', '');

  return (
    <div className={cn(css.textsSection, className)}>
      <div className={css.wrapper}>
        {title && <h1 className={css.title}>{title}</h1>}
        {subTitle && <div className={css.subTitle} dangerouslySetInnerHTML={{ __html: subTitle }}></div>}
      </div>
    </div>
  );
};

export default TextsSection;

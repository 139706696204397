import { useField } from 'formik';
import cn from 'classnames';
import React, { useState } from 'react';
import _ from 'lodash';
import { Datepicker, localeHe } from '@mobiscroll/react';
import format from 'date-fns/format';
import { he } from 'date-fns/locale';

import { ClientFieldItem } from 'models/form/interface';
import { useResponsive } from 'client/hooks';

import { ErrorLabel } from '../ErrorLabel';

import './Mobiscroll.scss';
import css from './DatePicker.module.scss';

interface Props {
  className?: string;
  name: string;
  fieldInfo: ClientFieldItem;
}

const DatePicker: React.FC<Props> = (props) => {
  const { className = '', name, fieldInfo } = props;
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(name);
  const isError = meta.error?.[0] && meta.touched;
  const [isDesktop] = useResponsive('DESKTOP');
  const isRequired = _.get(fieldInfo, 'validation.required', false);

  const onClose = (e: any) => {
    if (e.type === 'onClose') setOpen(false);
  };

  const onChange = (event: { value: Date }) => {
    helpers.setValue(event.value.toISOString());
  };

  const fieldProps = {
    ...field,
    onReset: () => {
      console.info('reset');
    },
    onClick: () => {
      setOpen(true);
    },
  };

  return (
    <div className={cn(css.datePicker, isError && !open && css.withError, className)}>
      <div className={css.datePickerWrap}>
        <Datepicker
          cssClass={css.mbDatepicker}
          onClose={onClose}
          controls={['calendar']}
          select="date"
          theme="material"
          themeVariant="light"
          locale={localeHe}
          rtl={true}
          isOpen={open}
          // display={isAlwaysVisible ? 'inline' : 'bubble'}
          value={field.value ? new Date(field.value) : undefined}
          onChange={onChange}
          touchUi={!isDesktop}
          inputComponent="input"
          inputProps={{ className: css.hiddenInput }}
        />
      </div>
      <div className={cn(css.inputWrapper, (open || field.value) && css.focused)}>
        <p className={css.label}>{isRequired ? `*${fieldInfo.label}` : fieldInfo.label || ''}</p>
        <input
          {...fieldProps}
          readOnly
          value={field.value ? format(new Date(field.value), 'dd MMMM, yyyy', { locale: he }) : ''}
        />
      </div>

      {isError && !open && <ErrorLabel text={meta.error?.[0] || ''} />}
    </div>
  );
};

export default DatePicker;

import * as yup from 'yup';

import { leads } from 'models/index';
import { LEADS_CREATE } from 'routes/api/constants';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type CreateParams = Omit<leads.IModel, '_id' | 'formId' | 'createdAt' | 'updatedAt'> & {
  formId: string;
  isTermsAccepted?: boolean;
};
export type CreateResult = { data: undefined };
export type CreateError =
  | EndpointError
  | {
      code:
        | ENDPOINT_ERROR.FORM_IS_NOT_EXIST
        | ENDPOINT_ERROR.FORM_IS_NOT_ACTIVE
        | ENDPOINT_ERROR.LEAD_ALREADY_EXIST
        | ENDPOINT_ERROR.TERMS_IS_NOT_ACCEPTED
        | ENDPOINT_ERROR.FORM_FIELDS_LEAD_FIELDS_MISMATCH;
    };

export const createParamsSchema = yup.object().shape({
  formId: yup.string().required(VALIDATION_ERROR.REQUIRED),
  fields: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(VALIDATION_ERROR.REQUIRED),
        value: yup
          .mixed()
          .test(
            'value',
            'The "value" must be a "string" or "boolean"',
            (val) => typeof val === 'string' || typeof val === 'boolean',
          )
          .required(VALIDATION_ERROR.REQUIRED),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
  urlParams: yup.object().optional(),
  isTermsAccepted: yup.boolean().optional(),
});

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => LEADS_CREATE,
});

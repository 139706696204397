import { SHARE_CONSTANTS } from 'client/constants';

export type ShareType = (typeof SHARE_CONSTANTS)[keyof typeof SHARE_CONSTANTS];

export const share = (type: ShareType, isDesktop: boolean | undefined) => {
  const domain = window.location.href;
  const titleProp = document.querySelector('meta[property="og:title"]');
  const title = titleProp ? titleProp.getAttribute('content') : '';
  const descriptionProp = document.querySelector('meta[property="og:description"]');
  const description = descriptionProp ? descriptionProp.getAttribute('content') : '';
  const encode = encodeURIComponent;
  const text = `${title}\n${description}\n${domain}`;

  if (type === 'facebook') {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
  }

  if (type === 'whatsapp') {
    const waShareLink = `https://wa.me/?text=${encode(`${text}`)}`;

    if (isDesktop) {
      window.open(`https://web.whatsapp.com/send?text=${encode(title + '\n' + description + '\n' + domain)}`, '_blank');
    } else {
      window.location.href = waShareLink;
    }
  }
};

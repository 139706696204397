export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const REG_EXP = {
  NUMBERS: /^[0-9\b]+$/,
  HEBREW_ONLY_LETTERS: /^[\u0590-\u05FF ,.'"-]+$/i,
  HEBREW_WITH_NUMBERS: /^[\u0590-\u05FF 0-9 ,.'"-]+$/,
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  ENGLISH: /[a-zA-Z 0-9]/,
};

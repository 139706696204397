import { CAN_USE_DOM } from 'src/constants';

export const imageWidthAndHeight = (provideFile: File): Promise<{ width: number; height: number }> => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: 0, height: 0 };

  return new Promise((resolve) => {
    if (CAN_USE_DOM) {
      const reader = new FileReader();
      reader.readAsDataURL(provideFile);
      reader.onload = function () {
        const img = new Image();
        // @ts-expect-error test
        img.src = reader.result;

        img.onload = function () {
          imgDimensions.width = img.width;
          imgDimensions.height = img.height;

          resolve(imgDimensions);
        };

        img.onerror = function () {
          resolve(imgDimensions);
        };
      };
    } else {
      // TODO: find a way to get image size
      resolve(imgDimensions);
    }
  });
};

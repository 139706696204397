import qs from 'qs';
import _ from 'lodash';

import { CONFIG } from 'constants/index';

import { ValidationResult } from './validation';

interface RequestParams {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: Record<string, unknown> | void;
  secure?: boolean;
}

export type ApiResponse<R, E = Record<string, unknown>> = ({ success: true } & R) | ({ success: false } & E);

/**
 * Http request helper
 */
export const api = {
  async request<R, E>(params: RequestParams) {
    const { url, method, body } = params;
    const query = method === 'GET' && !_.isEmpty(body) ? `?${qs.stringify(body)}` : '';
    const targetUrl = `${CONFIG.domainUrl}${url}${query}`;
    const headers = { 'Content-Type': 'application/json; charset=utf-8' };
    const options: RequestInit = { mode: 'cors' };

    options.method = method;
    options.headers = headers;
    if (method !== 'GET') options.body = JSON.stringify(body);

    const response = await fetch(targetUrl, options);
    const jsonResp = await response.json();
    const result: ApiResponse<R, E> = {
      ...jsonResp,
    };

    return result;
  },
};

/**
 * Endpoint wrap helper
 */
export type Endpoint<Params extends Record<string, unknown> | void, Result, Error> = (params: Params) => {
  args: {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
    body?: Params;
    secure?: boolean;
  };
  invoke: () => Promise<ApiResponse<Result, Error>>;
};

export const endpoint = <Params extends Record<string, unknown> | void, Result, Error>(fetchArgs: {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: (params: Params) => string;
}): Endpoint<Params, Result, Error> => {
  return (params) => ({
    args: {
      method: fetchArgs.method,
      url: `${fetchArgs.url(params)}${fetchArgs.method === 'GET' ? '?' + qs.stringify(params) : ''}`,
      body: fetchArgs.method !== 'GET' ? params : undefined,
    },
    invoke() {
      return api.request(this.args);
    },
  });
};

/**
 * Endpoint errors list
 */
export enum ERROR {
  INVALID_DATA = 'INVALID_DATA',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NO_SUCH_PROFILE = 'NO_SUCH_PROFILE',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST',
  ITEM_ALREADY_EXISTS = 'ITEM_ALREADY_EXISTS',
  FORM_IS_NOT_EXIST = 'FORM_IS_NOT_EXIST',
  FORM_IS_NOT_ACTIVE = 'FORM_IS_NOT_ACTIVE',
  TERMS_IS_NOT_ACCEPTED = 'TERMS_IS_NOT_ACCEPTED',
  LEAD_ALREADY_EXIST = 'LEAD_ALREADY_EXIST',
  FORM_FIELDS_LEAD_FIELDS_MISMATCH = 'FORM_FIELDS_LEAD_FIELDS_MISMATCH',
  ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS = 'ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS',
}

/**
 * Common error type
 */
export type EndpointError =
  | {
      code: ERROR.INVALID_DATA;
      validation: ValidationResult;
    }
  | {
      code: ERROR.ACCESS_DENIED;
    }
  | {
      code: ERROR.UNEXPECTED_ERROR;
      details: unknown;
    };

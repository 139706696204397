import * as yup from 'yup';

import { LEADS_EXPORT_CSV, LEADS_EXPORT_XLS } from 'api/constants';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type ExportParams = { fromDate: string; toDate: string; formId?: string };
export type ExportResult = Buffer;
export type ExportError = EndpointError | { code: ENDPOINT_ERROR.ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS };

export const exportParamsSchema = yup.object().shape({
  fromDate: yup.string().required(VALIDATION_ERROR.REQUIRED),
  toDate: yup.string().required(VALIDATION_ERROR.REQUIRED),
  formId: yup.string().optional(),
});

export const exportCSV = endpoint<ExportParams, ExportResult, ExportError>({
  method: 'GET',
  url: () => LEADS_EXPORT_CSV,
});

export const exportXLS = endpoint<ExportParams, ExportResult, ExportError>({
  method: 'GET',
  url: () => LEADS_EXPORT_XLS,
});

import cn from 'classnames';
import React from 'react';

import css from './ErrorLabel.module.scss';

interface Props {
  className?: string;
  text: string;
}

export const ErrorLabel: React.FC<Props> = (props) => {
  const { className = '', text } = props;

  return <div className={cn(css.errorLabel, className)}>{text}</div>;
};

/**
 * Converts a local date to UTC preserving timezone offset.
 * For example, if you are at GMT+3 and pass in 00:00, you will get 03:00 in same timezone,
 * but when it comes to convert it to UTC, it will be converted as 00:00 GMT+0 (not like 21:00 of previous day)
 */
export function localAsUTC(date: Date) {
  const offset = date.getTimezoneOffset();

  return new Date(date.getTime() - offset * 60 * 1000);
}

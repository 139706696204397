import _ from 'lodash';
import { startOfDay } from 'date-fns';

import { REG_EXP } from 'constants/index';
import { VALIDATION_ERROR } from 'endpoints/index';
import { LeadFieldItem } from 'models/lead/interface';
import { ClientFieldItem } from 'models/form/interface';
import { VALIDATION_TYPE } from 'models/inputField/interface';

import { localAsUTC } from './local-time-as-utc';

export const customValidate = (values: Array<LeadFieldItem>, fieldsInfo: Array<ClientFieldItem>) => {
  const errors: Record<string, string[]> = {};
  const rawErrors: Record<string, VALIDATION_ERROR[]> = {};

  values.forEach((item) => {
    const fieldInfo = fieldsInfo.find((f) => f.id.toString() === item.id);

    if (fieldInfo) {
      (Object.keys(fieldInfo.validation ?? {}) as VALIDATION_TYPE[]).forEach((validationName) => {
        const data =
          typeof fieldInfo.validation[validationName] === 'string'
            ? (fieldInfo.validation[validationName] as string)
            : undefined;
        const isValid = validator[validationName](item.value, data);

        if (!isValid) {
          _.set(errors, fieldInfo?.id.toString() || '', [
            ...(errors[fieldInfo.id.toString()] || []),
            fieldInfo?.validationMessages[validationName],
          ]);
          _.set(rawErrors, fieldInfo?.id.toString() || '', [
            ...(rawErrors[fieldInfo.id.toString()] || []),
            VALIDATION_ERROR.REQUIRED,
          ]);
        }
      });
    } else {
      _.set(errors, 'general', ['error while custom validation, fieldInfo is Empty']);
      _.set(rawErrors, 'general', ['error while custom validation, fieldInfo is Empty']);
    }
  });

  return { errors, rawErrors };
};

export const validator = {
  [VALIDATION_TYPE.REQUIRED]: (value: string | boolean) => Boolean(value) && value !== '',

  [VALIDATION_TYPE.CHARACTERS_LENGTH]: (value: string | boolean, data?: string) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return typeof value === 'string' && value.length === Number(data);
  },

  [VALIDATION_TYPE.EMAIL]: (value: string | boolean) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return REG_EXP.EMAIL.test(value as string);
  },

  [VALIDATION_TYPE.HOME_PHONE]: (value: string | boolean) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return typeof value === 'string' && value.length === 9;
  },

  [VALIDATION_TYPE.MOBILE_PHONE]: (value: string | boolean) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return typeof value === 'string' && value.length === 10;
  },

  [VALIDATION_TYPE.IS_PAST_DATE_NOT_SELECTABLE]: (value: string | boolean) => {
    if (typeof value === 'string') {
      const now = new Date();
      const selectedDate = localAsUTC(value ? new Date(value) : now);
      const nowDate = localAsUTC(startOfDay(now));

      return typeof value === 'string' && selectedDate >= nowDate;
    }

    return false;
  },

  [VALIDATION_TYPE.MAX_LENGTH]: (value: string | boolean, data?: string) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return typeof value === 'string' && value.length <= Number(data);
  },

  [VALIDATION_TYPE.MIN_LENGTH]: (value: string | boolean, data?: string) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return typeof value === 'string' && value.length >= Number(data) && value.length !== 0;
  },

  [VALIDATION_TYPE.ONLY_HEBREW]: (value: string | boolean) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return REG_EXP.HEBREW_ONLY_LETTERS.test(value as string);
  },

  [VALIDATION_TYPE.ONLY_NUMBERS]: (value: string | boolean) => {
    if (typeof value === 'string' && value.length === 0) return true;
    return REG_EXP.NUMBERS.test(value as string);
  },
};

import cn from 'classnames';
import React from 'react';

import css from './Modal.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  withCloseBtn?: boolean;
  closeable?: boolean;
  title?: string;
  isOpen: boolean;
}

export const Modal: React.FC<Props> = (props) => {
  const {
    className = '',
    contentClassName = '',
    closeable = true,
    withCloseBtn = true,
    isOpen,
    title,
    children,
    onClose,
  } = props;

  if (!isOpen) {
    return null;
  }

  const onCloseModal = () => {
    onClose?.();
  };

  return (
    <div className={cn(css.modal, className)}>
      <div className={css.bg} onClick={closeable ? onCloseModal : undefined} />

      <div className={cn(css.content, contentClassName)}>
        {withCloseBtn && closeable && <button onClick={onCloseModal} className={css.closeBtn} />}
        {title && <div className={css.title}>{title}</div>}

        {children}
      </div>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';
import { useResponsive } from 'client/hooks';
import css from './Icon.module.scss';

export type IconType = 'share' | 'whatsapp' | 'facebook' | 'accessibility' | 'phone';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
  shapeColor?: string;
}

const Icon = ({ type, className, width, height, color = '#000', secondaryColor, shapeColor, ...iconProps }: Props) => {
  let icon = null;
  const [isDesktop] = useResponsive('DESKTOP');
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width || 38} ${height || 38}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'share':
      icon = (
        <svg {...svgProps} width="18px" height="19px" viewBox="0 0 18 19">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-26.000000, -25.000000)" fill={color}>
              <g transform="translate(19.000000, 16.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <path d="M18.9071445,21.0668198 L13.2835484,17.9733159 C13.3708825,17.6968823 13.4182477,17.4037144 13.4182477,17.0999998 C13.4182477,16.7961441 13.3708461,16.5028707 13.2834027,16.2262613 L18.9068895,13.1328629 C19.4752718,13.727109 20.2885685,14.1000465 21.1909483,14.1000465 C22.9056775,14.1000465 24.2999994,12.7545114 24.2999994,11.0999528 C24.2999994,9.44553491 22.9056775,8.09999979 21.1909483,8.09999979 C19.4762191,8.09999979 18.0818972,9.44553491 18.0818972,11.0999528 C18.0818972,11.3449216 18.1131582,11.5828591 18.170798,11.8109528 L12.4707617,14.9465387 C11.9108323,14.4230973 11.1486171,14.1000465 10.3090509,14.1000465 C8.59446744,14.1000465 7.19999981,15.445441 7.19999981,17.0999998 C7.19999981,18.7545229 8.59446744,20.0999526 10.3090509,20.0999526 C11.1487264,20.0999526 11.9110874,19.7767964 12.4710168,19.2532143 L18.1709073,22.3886596 C18.1131947,22.6168588 18.0818972,22.8549017 18.0818972,23.1000463 C18.0818972,24.7544642 19.4762191,26.0999998 21.1909483,26.0999998 C22.9056775,26.0999998 24.2999994,24.7544642 24.2999994,23.1000463 C24.2999994,21.4454877 22.9056775,20.0999526 21.1909483,20.0999526 C20.2887143,20.0999526 19.4755633,20.4727846 18.9071445,21.0668198 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
      break;

    case 'whatsapp':
      icon = (
        <svg {...svgProps}>
          <path
            fill="#2ab200"
            d="M14249 6987a16 16 0 1 1 16 16 16 16 0 0 1-16-16zm6.763-.266a9.56 9.56 0 0 0 1.28 4.789l-1.358 4.96 5.074-1.333a9.6 9.6 0 0 0 4.577 1.168 9.577 9.577 0 1 0-9.577-9.584zm9.573 7.964a7.949 7.949 0 0 1-4.05-1.107l-.29-.172-3.015.789.807-2.936-.19-.3a7.96 7.96 0 1 1 6.742 3.728zm-4.024-12.082a2.674 2.674 0 0 0-.835 1.993 4.678 4.678 0 0 0 .975 2.477 10.7 10.7 0 0 0 4.089 3.609 13.637 13.637 0 0 0 1.362.505 3.233 3.233 0 0 0 1.509.093 2.452 2.452 0 0 0 1.613-1.136 1.981 1.981 0 0 0 .14-1.136c-.062-.1-.219-.161-.459-.279s-1.416-.7-1.635-.778-.38-.122-.537.118-.62.778-.756.939-.28.179-.521.058a6.489 6.489 0 0 1-1.924-1.187 7.162 7.162 0 0 1-1.334-1.656c-.136-.24 0-.358.107-.487a6.871 6.871 0 0 0 .6-.821.439.439 0 0 0-.021-.419c-.057-.118-.537-1.294-.738-1.774-.172-.412-.348-.412-.487-.412h-.05c-.14-.007-.3-.007-.459-.007a.88.88 0 0 0-.64.3z"
            transform="translate(-14248.999 -6971)"
          />
        </svg>
      );
      break;
    case 'facebook':
      icon = (
        <svg {...svgProps}>
          <path
            fill="#1877f2"
            d="M16 .349a15.991 15.991 0 0 0-2.668 31.76V19.687h-3.86v-4.47h3.86v-3.3c0-3.824 2.336-5.908 5.748-5.908a31.853 31.853 0 0 1 3.446.175v4h-2.367c-1.855 0-2.213.881-2.213 2.175v2.853h4.427l-.577 4.47h-3.85v12.534A15.992 15.992 0 0 0 16 .349z"
            transform="translate(0 -0.349)"
          />
        </svg>
      );
      break;
    case 'accessibility':
      icon = (
        <svg {...svgProps}>
          <g id="ng" transform="translate(-18 -24)">
            <rect
              id="Rectangle_299"
              data-name="Rectangle 299"
              width={width}
              height={height}
              rx={width ? width / 2 : '17'}
              transform="translate(18 24)"
              fill={color}
            />
            <g id="XMLID_28_" transform={`${isDesktop ? 'translate(-19.379 32)' : 'translate(-15.379 36)'}`}>
              <path
                id="XMLID_31_"
                fill="#fff"
                d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
                transform="translate(-124.881)"
              />
              <path
                id="XMLID_30_"
                fill="#fff"
                d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
                transform="translate(0 -191.581)"
              />
              <path
                id="XMLID_29_"
                fill="#fff"
                d="m191.737 128.291-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
                transform="translate(-130.467 -113.162)"
              />
            </g>
          </g>
        </svg>
      );
      break;
    case 'phone':
      icon = (
        <svg
          {...svgProps}
          height="800px"
          width="800px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 202.592 202.592"
        >
          <g>
            <g>
              <path
                fill={shapeColor}
                d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
              />
            </g>
          </g>
        </svg>
      );
      break;
    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  secondaryColor: '#000',
};

export default Icon;

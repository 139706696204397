import * as yup from 'yup';

import { FORMS_CHECK_SLUG_UNIQUENESS } from 'routes/api/constants';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type CheckUniqSlugParams = { slug: string };
export type CheckUniqSlugResult = unknown;
export type CheckUniqSlugError = EndpointError | { code: ENDPOINT_ERROR.ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS };

export const checkUniqSlugParamsSchema = yup.object().shape({
  slug: yup
    .string()
    .required(VALIDATION_ERROR.REQUIRED)
    .matches(/^[a-z_\-]+$/, {
      message: 'slug field allows only lower case letters without white spaces and next symbols: _,-',
      excludeEmptyString: true,
    }),
});

export const checkSlugUniqueness = endpoint<CheckUniqSlugParams, CheckUniqSlugResult, CheckUniqSlugError>({
  method: 'POST',
  url: () => FORMS_CHECK_SLUG_UNIQUENESS,
});

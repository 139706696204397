import { inputField } from 'models/index';
import { INPUT_FIELD_UPDATE } from 'routes/api/constants';

import { endpoint, EndpointError } from '../endpoint';
import { createParamsSchema } from './create';

export type UpdateParams = Partial<inputField.IModel>;
export type UpdateResult = { data: undefined };
export type UpdateError = EndpointError;

export const updateParamsSchema = createParamsSchema;

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'PUT',
  url: () => INPUT_FIELD_UPDATE,
});

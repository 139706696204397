import * as yup from 'yup';

import { FORMS_CREATE } from 'routes/api/constants';
import {
  FieldItem,
  FormContent,
  FormDesign,
  FormGeneral,
  FormOther,
  ONCE_PER_USER_OPTIONS,
} from 'models/form/interface';
import { FIELD_INPUT_TYPE, FIELD_TYPE, FIELD_WIDTH, VALIDATION_TYPE } from 'models/inputField/interface';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ERROR as VALIDATION_ERROR, generalErrors } from '../validation';
import { imageWidthAndHeight } from './helpers';

export type CreateParams = {
  general: FormGeneral;
  design: FormDesign;
  formContent: FormContent;
  fields: Array<FieldItem>;
  other: FormOther;
  isActive: boolean;
};
export type CreateResult = { data: undefined };
export type CreateError = EndpointError | { code: ENDPOINT_ERROR.ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS };

const getYupMixedImageRule = (params: {
  supportedFormats: Array<string>;
  fileSize?: number;
  dimensions?: [number, number] | null;
}) => {
  // * dimensions = [1600, 630] => dimensions[0] - width, dimensions[1] - height
  const { supportedFormats, fileSize = 100 * 1024 * 1024, dimensions = null } = params;
  return yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.UNSUPPORTED_FILE_FORMAT, (value) => {
      return value && typeof value === 'object' ? supportedFormats.includes(value.mimetype || value.type) : true;
    })
    .test('size', VALIDATION_ERROR.FILE_IS_TOO_LARGE, (value) => {
      return value && typeof value === 'object' ? value.size <= fileSize : true;
    })
    .test(
      'widthHeight',
      generalErrors.FILE_HAS_WRONG_WIDTH_OR_HEIGHT.replace(/width/i, dimensions ? `${dimensions[0]}` : '').replace(
        /height/i,
        dimensions ? `${dimensions[1]}` : '',
      ),
      async (value) => {
        if (value && typeof value === 'object' && dimensions) {
          const res = await imageWidthAndHeight(value);

          if (res.width === 0 && res.height === 0) return true;

          return res.width === dimensions[0] && res.height === dimensions[1];
        }

        return true;
      },
    );
};

export const createParamsSchema = yup.object().shape({
  general: yup
    .object()
    .shape({
      name: yup.string().required(VALIDATION_ERROR.REQUIRED),
      slug: yup
        .string()
        .required(VALIDATION_ERROR.REQUIRED)
        .matches(/^[a-z0-9_\-]+$/, {
          message: VALIDATION_ERROR.SLUG_REGEXP_ERROR,
          excludeEmptyString: true,
        }),

      gaID: yup.string().optional(),
      metaTitle: yup.string().required(VALIDATION_ERROR.REQUIRED),
      metaDescription: yup.string().required(VALIDATION_ERROR.REQUIRED),
      shareImg: getYupMixedImageRule({
        supportedFormats: ['image/png', 'image/jpeg', 'image/jpg'],
        fileSize: 300 * 1024,
        dimensions: [1200, 630],
      }).required(VALIDATION_ERROR.REQUIRED),
      shareTitle: yup.string().required(VALIDATION_ERROR.REQUIRED),
      shareDescription: yup.string().required(VALIDATION_ERROR.REQUIRED),
      isShareIconsVisible: yup.boolean().optional(),
      pixels: yup.string().optional(),
      isRegisterOncePerUser: yup.boolean().optional(),
      oncePerUserBy: yup
        .string()
        .oneOf(Object.values(ONCE_PER_USER_OPTIONS), VALIDATION_ERROR.INVALID_VALUE)
        .optional()
        .when(['isRegisterOncePerUser'], {
          is: true,
          then: yup
            .string()
            .oneOf(Object.values(ONCE_PER_USER_OPTIONS), VALIDATION_ERROR.INVALID_VALUE)
            .required(VALIDATION_ERROR.REQUIRED),
        }),
      isUseActiveTrail: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
    })
    .required(VALIDATION_ERROR.REQUIRED),
  design: yup
    .object()
    .shape({
      desktopBg: getYupMixedImageRule({
        supportedFormats: ['image/png', 'image/jpeg', 'image/jpg'],
        fileSize: 2 * 1024 * 1024,
      }).required(VALIDATION_ERROR.REQUIRED),
      mobileBg: getYupMixedImageRule({
        supportedFormats: ['image/png', 'image/jpeg', 'image/jpg'],
        fileSize: 2 * 1024 * 1024,
      }).required(VALIDATION_ERROR.REQUIRED),
      logo1: getYupMixedImageRule({
        supportedFormats: ['image/png', 'image/jpeg', 'image/jpg'],
        fileSize: 300 * 1024,
      }).optional(),
      logo2: getYupMixedImageRule({
        supportedFormats: ['image/png', 'image/jpeg', 'image/jpg'],
        fileSize: 300 * 1024,
      }).optional(),
      logo1Link: yup.string().optional(),
      logo2Link: yup.string().optional(),
      sendButtonColor: yup.string().optional(),
      pageBackgroundColor: yup.string().optional(),
      formTitleTextColor: yup.string().optional(),
      linkTextColor: yup.string().optional(),
      fieldTextColor: yup.string().optional(),
    })
    .required(VALIDATION_ERROR.REQUIRED),
  other: yup
    .object()
    .shape(
      {
        isShowTerms: yup.boolean().optional(),
        termsFile: getYupMixedImageRule({
          supportedFormats: ['application/pdf'],
        })
          .optional()
          .when(['isShowTerms', 'conditionsLink', 'privacyFile'], {
            is: (isShowTerms: boolean, conditionsLink: any, privacyFile: any) => {
              return isShowTerms && !conditionsLink && !privacyFile;
            },
            then: getYupMixedImageRule({
              supportedFormats: ['application/pdf'],
            }).required(VALIDATION_ERROR.REQUIRED),
          }),
        privacyFile: getYupMixedImageRule({
          supportedFormats: ['application/pdf'],
        })
          .optional()
          .when(['isShowTerms', 'termsFile', 'conditionsLink'], {
            is: (isShowTerms: boolean, termsFile: any, conditionsLink: string) => {
              return isShowTerms && !conditionsLink && !termsFile;
            },
            then: getYupMixedImageRule({
              supportedFormats: ['application/pdf'],
            }).required(VALIDATION_ERROR.REQUIRED),
          }),
        conditionsLink: getYupMixedImageRule({
          supportedFormats: ['application/pdf'],
        })
          .optional()
          .when(['isShowTerms', 'termsFile', 'privacyFile'], {
            is: (isShowTerms: boolean, termsFile: any, privacyFile: any) => {
              return isShowTerms && !termsFile && !privacyFile;
            },
            then: getYupMixedImageRule({
              supportedFormats: ['application/pdf'],
            }).required(VALIDATION_ERROR.REQUIRED),
          }),
        acceptText: yup
          .string()
          .optional()
          .when(['isShowTerms'], {
            is: true,
            then: yup
              .string()
              .required(VALIDATION_ERROR.REQUIRED)
              .test('customTest', VALIDATION_ERROR.ACCEPT_TEXT_WRONG_STRUCTURE, (value, context) => {
                if (
                  (context.parent.termsFile && !value?.includes('{תקנון}')) ||
                  (context.parent.conditionsLink && !value?.includes('{תנאי שימוש}')) ||
                  (context.parent.privacyFile && !value?.includes('{מדיניות פרטיות}'))
                ) {
                  return context.createError({
                    message:
                      'חסר שומר מקום עבור התקנון , תנאי השימוש או מדיניות הפרטיות שהועלו. נא להוסיף את שומר המקום שחסר',
                  });
                } else if (
                  (!context.parent.termsFile && value?.includes('{תקנון}')) ||
                  (!context.parent.conditionsLink && value?.includes('{תנאי שימוש}')) ||
                  (!context.parent.privacyFile && value?.includes('{מדיניות פרטיות}'))
                ) {
                  return context.createError({ message: generalErrors[VALIDATION_ERROR.ACCEPT_TEXT_WRONG_STRUCTURE] });
                }

                return true;
              }),
          }),
        isDisplayCallButton: yup.boolean().optional(),
        callButtonPhone: yup
          .string()
          .optional()
          .when(['isDisplayCallButton'], {
            is: true,
            then: yup.string().required(VALIDATION_ERROR.REQUIRED),
          }),
        callButtonText: yup
          .string()
          .optional()
          .when(['isDisplayCallButton'], {
            is: true,
            then: yup.string().required(VALIDATION_ERROR.REQUIRED),
          }),
      },
      [
        ['conditionsLink', 'termsFile'],
        ['conditionsLink', 'privacyFile'],
        ['termsFile', 'privacyFile'],
      ],
    )
    .required(VALIDATION_ERROR.REQUIRED),
  fields: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(VALIDATION_ERROR.REQUIRED),
        inputId: yup.string().required(VALIDATION_ERROR.REQUIRED),
        inputType: yup.string().oneOf(Object.values(FIELD_INPUT_TYPE)).required(VALIDATION_ERROR.REQUIRED),
        type: yup.string().oneOf(Object.values(FIELD_TYPE)).required(VALIDATION_ERROR.REQUIRED),
        name: yup
          .string()
          .required(VALIDATION_ERROR.REQUIRED)
          .matches(/^[a-z]([a-z0-9]*[a-z][a-z0-9]*[A-Z]|[a-z0-9]*[A-Z][A-Z0-9]*[a-z])[A-Za-z0-9]|^[a-z]+/, {
            message: VALIDATION_ERROR.FIELD_FORM_NAME_REGEXP_ERROR,
            excludeEmptyString: true,
          }),
        label: yup.string().required(VALIDATION_ERROR.REQUIRED),
        other: yup
          .object()
          .shape({
            width: yup.string().oneOf(Object.values(FIELD_WIDTH)).required(VALIDATION_ERROR.REQUIRED),
          })
          .required(VALIDATION_ERROR.REQUIRED),
        options: yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string().required(VALIDATION_ERROR.REQUIRED),
              value: yup.string().required(VALIDATION_ERROR.REQUIRED),
              isVisible: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
            }),
          )
          .optional()
          .when(['type', 'inputType'], {
            is: (type: FIELD_TYPE, inputType: FIELD_INPUT_TYPE) =>
              type === FIELD_TYPE.DROPDOWN || inputType === FIELD_INPUT_TYPE.SELECT,
            then: yup
              .array()
              .of(
                yup.object().shape({
                  label: yup.string().required(VALIDATION_ERROR.REQUIRED),
                  value: yup.string().required(VALIDATION_ERROR.REQUIRED),
                  isVisible: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
                }),
              )
              .test('size', VALIDATION_ERROR.REQUIRED, (options) => {
                if (options?.filter((opt) => opt.isVisible).length === 0) {
                  return false;
                }

                return true;
              }),
          }),
        isVisible: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
        validation: yup
          .object()
          .shape({
            [VALIDATION_TYPE.REQUIRED]: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
            [VALIDATION_TYPE.CHARACTERS_LENGTH]: yup.string().optional(),
            [VALIDATION_TYPE.EMAIL]: yup.boolean().optional(),
            [VALIDATION_TYPE.HOME_PHONE]: yup.boolean().optional(),
            [VALIDATION_TYPE.IS_PAST_DATE_NOT_SELECTABLE]: yup.boolean().optional(),
            [VALIDATION_TYPE.MOBILE_PHONE]: yup.boolean().optional(),
            [VALIDATION_TYPE.ONLY_HEBREW]: yup.boolean().optional(),
            [VALIDATION_TYPE.ONLY_NUMBERS]: yup.boolean().optional(),
            [VALIDATION_TYPE.MAX_LENGTH]: yup.string().optional(),
            [VALIDATION_TYPE.MIN_LENGTH]: yup.string().optional(),
          })
          .required(VALIDATION_ERROR.REQUIRED),
        validationMessages: yup
          .object()
          .shape({
            [VALIDATION_TYPE.REQUIRED]: yup.string().required(VALIDATION_ERROR.REQUIRED),
            [VALIDATION_TYPE.CHARACTERS_LENGTH]: yup.string().optional(),
            [VALIDATION_TYPE.EMAIL]: yup.string().optional(),
            [VALIDATION_TYPE.HOME_PHONE]: yup.string().optional(),
            [VALIDATION_TYPE.IS_PAST_DATE_NOT_SELECTABLE]: yup.string().optional(),
            [VALIDATION_TYPE.MOBILE_PHONE]: yup.string().optional(),
            [VALIDATION_TYPE.ONLY_HEBREW]: yup.string().optional(),
            [VALIDATION_TYPE.ONLY_NUMBERS]: yup.string().optional(),
            [VALIDATION_TYPE.MAX_LENGTH]: yup.string().optional(),
            [VALIDATION_TYPE.MIN_LENGTH]: yup.string().optional(),
          })
          .required(VALIDATION_ERROR.REQUIRED),
      }),
    )
    .test('size', VALIDATION_ERROR.REQUIRED, (fields) => {
      if (fields?.filter((field) => field.isVisible).length === 0) {
        return false;
      }

      return true;
    })
    .test('oncePerUserCheck', VALIDATION_ERROR.ONCE_PER_USER_FIELD_MISMATCH, (fields, context) => {
      if (context.parent.general.oncePerUserBy && context.parent.general.isRegisterOncePerUser) {
        const targetFieldIndex =
          fields?.findIndex((field) => field.name === context.parent.general.oncePerUserBy && field.isVisible) ?? -1;

        if (targetFieldIndex < 0) return false;
        return true;
      }

      return true;
    }),
  formContent: yup
    .object()
    .shape({
      formTitle: yup.string().optional(),
      formSubTitle: yup.string().optional(),
      sendButtonText: yup.string().optional(),
      successfulRegistrationText: yup.string().optional(),
      completedActivityText: yup.string().optional(),
      attemptPlayTwiceText: yup.string().optional(),
    })
    .required(VALIDATION_ERROR.REQUIRED),
  isActive: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
});

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => FORMS_CREATE,
});

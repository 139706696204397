import * as yup from 'yup';

import { FORMS_GET } from 'routes/api/constants';

import { ERROR as VALIDATION_ERROR } from '../validation';
import { endpoint, EndpointError } from '../endpoint';
import { FormWithAdminInfo } from './paginate';

export type GetParams = { id: string };
export type GetResult = { data: FormWithAdminInfo };
export type GetError = EndpointError;

export const getParamsSchema = yup.object().shape({
  id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: (params) => FORMS_GET.replace(':id', params.id.toString()),
});

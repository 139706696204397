import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useAppSelector, useResponsive } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';

import css from './TopSection.module.scss';

interface Props {
  className?: string;
}

const TopSection: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const formInfo = useAppSelector(selectFormInfo);
  const desktopBg = _.get(formInfo, 'design.desktopBg', '');
  const mobileBg = _.get(formInfo, 'design.mobileBg', '');
  const [isMobile] = useResponsive('MOBILE');

  return (
    <div className={cn(css.topSection, className)}>
      <div className={css.imageWrapper}>
        <img src={isMobile ? mobileBg : desktopBg} alt="top section bacground" />
      </div>
    </div>
  );
};

export default TopSection;

import * as yup from 'yup';

import { forms } from 'models/index';
import { FORMS_UPDATE } from 'routes/api/constants';

import { ERROR as VALIDATION_ERROR } from '../validation';
import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { CreateParams, createParamsSchema } from './create';

export type UpdateParams = CreateParams & { _id: string };
export type UpdateResult = { data: forms.IModel };
export type UpdateError = EndpointError | { code: ENDPOINT_ERROR.ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS };

export const updateParamsSchema = createParamsSchema.shape({
  _id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'PUT',
  url: (params) => FORMS_UPDATE.replace(':id', params._id.toString()),
});

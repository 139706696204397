import * as yup from 'yup';

import { admins } from 'models/index';
import { ADMINS_GET } from 'routes/api/constants';

import { ERROR as VALIDATION_ERROR } from '../validation';
import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';

export type ReadParams = Pick<admins.IAdmin, '_id'>;
export type ReadResult = admins.IAdmin;
export type ReadError = EndpointError | { code: ENDPOINT_ERROR.NO_SUCH_PROFILE };

export const readParamsSchema = yup.object().shape({
  _id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const read = endpoint<ReadParams, ReadResult, ReadError>({
  method: 'GET',
  url: (params) => ADMINS_GET.replace(':id', params._id),
});

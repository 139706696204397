import cn from 'classnames';
import React from 'react';

import { Modal } from '../Modal';

import css from './WarningModal.module.scss';

interface Props {
  className?: string;
  isOpen: boolean;
  message: string;
}

export const WarningModal: React.FC<Props> = (props) => {
  const { className = '', isOpen, message } = props;
  const title = 'אזהרה';

  return (
    <Modal isOpen={isOpen} closeable={false} contentClassName={cn(css.warningModal, className)} title={title}>
      <div className={css.message}>{message}</div>
    </Modal>
  );
};

import cn from 'classnames';
import React from 'react';

import { Modal } from '../Modal';
import { Button } from '../Button';

import css from './SuccessModal.module.scss';

interface Props {
  className?: string;
  isOpen: boolean;
  message: string;
  onClose?: () => void;
}

export const SuccessModal: React.FC<Props> = (props) => {
  const { className = '', isOpen, message, onClose } = props;
  const title = 'הודעת מערכת';
  const submitBtnLabel = 'המשך';

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentClassName={cn(css.successModal, className)} title={''}>
      <div className={css.message}>{message}</div>

      <Button onClick={onClose} className={css['submit-btn']}>
        {submitBtnLabel}
      </Button>
    </Modal>
  );
};

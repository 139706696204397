import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

import css from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const Button: React.FC<Props> = (props) => {
  const { className = '', children, ...rest } = props;

  return (
    <button className={cn(css.button, className)} {...rest}>
      {children}
    </button>
  );
};

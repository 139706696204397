import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientModel } from 'models/form/interface';

type Form = {
  formInfo: ClientModel | null;
  isBusy: boolean;
};

const initialState: Form = {
  formInfo: null,
  isBusy: false,
};

export const formInfo = createSlice({
  name: 'formInfo',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<ClientModel>) => {
      state.formInfo = action.payload;
    },
  },
});
export const { init } = formInfo.actions;
export default formInfo.reducer;

export const API_ROOT = '/api' as const;

// Admin:
export const AUTH_ADMIN = `${API_ROOT}/auth/admin` as const;
export const AUTH_SESSION_IN = `${API_ROOT}/auth/session-in` as const;
export const AUTH_SESSION_OUT = `${API_ROOT}/auth/session-out` as const;

export const ADMINS_GET = `${API_ROOT}/admins/get/:id` as const;
export const ADMINS_CREATE = `${API_ROOT}/admins/create` as const;
export const ADMINS_UPDATE = `${API_ROOT}/admins/update/:id` as const;
export const ADMINS_DELETE = `${API_ROOT}/admins/delete/:id` as const;
export const ADMINS_PAGINATE = `${API_ROOT}/admins/paginate` as const;

// Form
export const FORMS_GET = `${API_ROOT}/forms/get/:id` as const;
export const FORMS_PUBLIC_GET = `${API_ROOT}/forms/public/get/:id` as const;
export const FORMS_CREATE = `${API_ROOT}/forms/create` as const;
export const FORMS_CHECK_SLUG_UNIQUENESS = `${API_ROOT}/forms/check-slug-uniqueness` as const;
export const FORMS_UPDATE = `${API_ROOT}/forms/update/:id` as const;
export const FORMS_PAGINATE = `${API_ROOT}/forms/paginate` as const;

// Lead
export const LEADS_GET = `${API_ROOT}/leads/:id` as const;
export const LEADS_CREATE = `${API_ROOT}/leads/create` as const;
export const LEADS_PAGINATE = `${API_ROOT}/leads/paginate` as const;
export const LEADS_EXPORT_CSV = `${API_ROOT}/leads/export-csv` as const;
export const LEADS_EXPORT_XLS = `${API_ROOT}/leads/export-xls` as const;

// Input Field
export const INPUT_FIELD_CREATE = `${API_ROOT}/input-field/create` as const;
export const INPUT_FIELD_UPDATE = `${API_ROOT}/input-field/update` as const;
export const INPUT_FIELD_GET = `${API_ROOT}/input-field/get` as const;

// CSV
export const CSV_EXPORT = `${API_ROOT}/csv/export` as const;

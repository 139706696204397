import qs from 'qs';
import _ from 'lodash';
import cn from 'classnames';
import { useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import { FormikContext, useFormik, Form as FormikForm } from 'formik';

import { selectFormInfo } from 'client/redux/formInfo/selectors';
import { useAppSelector, useResponsive } from 'client/hooks';
import { lead as leadApi } from 'client/redux/api/lead';
import { LeadFieldItem } from 'models/lead/interface';
import { customValidate } from 'utils/validator';
import { ERROR } from 'endpoints/endpoint';
import { SubmitError } from 'client/types';
import { analytics } from 'client/utils';
import { lead } from 'endpoints/index';

import Field from './Field';
import { Button } from '../Button';
import { SuccessModal } from '../SuccessModal';
import { FormSubmitErrorModal } from '../FormSubmitErrorModal';
import { Conditions } from '../Conditions';
import { CallButton } from '../CallButton';

import css from './Form.module.scss';

interface Props {
  className?: string;
}

type FormValues = Record<string, string | boolean>;

let createLeadAttemptsCount = 0;

const Form: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const formInfo = useAppSelector(selectFormInfo);
  const fields = formInfo?.fields ?? [];
  const isShowConditions = formInfo?.other.isShowTerms ?? false;
  const location = useLocation();
  const [submitError, setSubmitError] = useState<SubmitError | undefined>(undefined);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [createLead, result] = leadApi.endpoints.submit.useMutation();
  const [isMobile] = useResponsive('MOBILE');
  const requiredText = 'נדרש';
  const isCallButton = _.get(formInfo, 'other.isDisplayCallButton', false);
  const isShowCallButton = isMobile && isCallButton;

  const initialValues = () => {
    const values: Record<string, string | boolean> = {};

    fields.forEach((field) => {
      values[`${field.id.toString()}`] = field.inputType === 'boolean' ? false : '';
    });

    if (isShowConditions) {
      values.isTermsAccepted = false;
    }

    return values;
  };

  useEffect(() => {
    if (result.status === 'fulfilled' && result.data.success) {
      setIsSuccessModalVisible(true);

      // analytics.gtag.event('form_submission', { page_title: formInfo?.general.metaTitle ?? '' });
      analytics.gtm.event('form_submission', { page_title: formInfo?.general.metaTitle ?? '' });
    }

    if (result.status === 'rejected' && !result.data?.success) {
      const errorCode = (result.error as { data: Omit<lead.CreateError, 'code'> & { code: SubmitError } }).data?.code;

      if (!errorCode && createLeadAttemptsCount < 2) {
        createLeadAttemptsCount++;
        createLead(createLeadPayload());
      } else if (!errorCode) {
        setIsSuccessModalVisible(true);
      } else {
        setSubmitError(errorCode);
      }
    }
  }, [result]);

  const onErrorModalCloseClick = () => {
    setSubmitError(undefined);
    createLeadAttemptsCount = 0;
  };

  const onSuccessModalCloseClick = () => {
    setIsSuccessModalVisible(false);
    formikContext.resetForm();
    createLeadAttemptsCount = 0;
  };

  const createLeadPayload = (values?: FormValues): lead.CreateParams => {
    const formValues = values ?? formikContext.values;

    return {
      formId: formInfo?._id.toString() ?? '',
      fields: Object.keys(formValues).reduce<LeadFieldItem[]>((acc, key) => {
        if (key !== 'isTermsAccepted') acc.push({ id: key, value: formValues[key] });
        return acc;
      }, []),
      urlParams: qs.parse(location.search, { ignoreQueryPrefix: true }) as any,
      isTermsAccepted: Boolean(formValues.isTermsAccepted) || false,
    };
  };

  const formikContext = useFormik<FormValues>({
    initialValues: initialValues(),
    onSubmit: async (values) => {
      if (formInfo?.isActive) {
        createLead(createLeadPayload(values));
      } else {
        setSubmitError(ERROR.FORM_IS_NOT_ACTIVE);
      }
    },
    validate: (values) => {
      const listOfValues = Object.keys(values).reduce<LeadFieldItem[]>((acc, key) => {
        if (key !== 'isTermsAccepted') acc.push({ id: key, value: values[key] });
        return acc;
      }, []);
      const { errors } = customValidate(listOfValues, formInfo?.fields || []);

      return {
        ...errors,
        ...(isShowConditions && !values.isTermsAccepted && { isTermsAccepted: requiredText }),
      };
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  return (
    <div className={cn(css.form, className)}>
      <FormikContext.Provider value={formikContext}>
        <FormikForm>
          <div className={cn(css.formContent, className)}>
            <div className={css.fields}>
              <div className={css.fieldsInner}>
                {fields.map((field) => {
                  return (
                    <div key={field.id.toString()} className={cn(css.fieldWrapper, css[field.other.width])}>
                      <Field fieldInfo={field} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={cn(css.submitAndConditions, !isShowConditions && css.contentCenter)}>
              {isShowConditions && <Conditions name="isTermsAccepted" />}
              <Button className={css.submitBtn} disabled={result.isLoading} type="submit">
                {formInfo?.formContent.sendButtonText}
              </Button>
            </div>
          </div>
          {isShowCallButton && <CallButton />}
        </FormikForm>
      </FormikContext.Provider>

      {Boolean(submitError) && <FormSubmitErrorModal isOpen errorCode={submitError} onClose={onErrorModalCloseClick} />}
      {isSuccessModalVisible && (
        <SuccessModal
          isOpen
          message={formInfo?.formContent.successfulRegistrationText || 'Ok'}
          onClose={onSuccessModalCloseClick}
        />
      )}
    </div>
  );
};

export default Form;

import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';
import cn from 'classnames';

import { useAppSelector } from 'client/hooks';
import { selectFormInfo } from 'client/redux/formInfo/selectors';

import { ErrorLabel } from '../ErrorLabel';

import css from './Conditions.module.scss';

interface Props {
  className?: string;
  name: string;
}

export const Conditions: React.FC<Props> = (props) => {
  const { className = '', name } = props;
  const [field, meta, helpers] = useField(name);
  const isError = meta.error?.[0] && meta.touched;
  const formInfo = useAppSelector(selectFormInfo);
  const acceptText = _.get(formInfo, 'other.acceptText', '');

  const termsText = (text: string) => {
    const regEx = /\{(.+?)\}/g;
    const textsInBrakets = text.match(regEx);

    let outputString = '';
    textsInBrakets?.forEach((item, index) => {
      let link = '';
      let newText = '';
      if (item === CONDITIONS) {
        link = _.get(formInfo, 'other.conditionsLink', '');
        if (!link) {
          newText = '';
        } else {
          newText = `<a href="${link}" target="_blank" rel="noopener noreferrer">${TEXTS.conditions}</a>`;
        }
      } else if (item === PRIVACY) {
        link = _.get(formInfo, 'other.privacyFile', '');
        if (!link) {
          newText = '';
        } else {
          newText = `<a href="${link}" target="_blank" rel="noopener noreferrer">${TEXTS.privacy}</a>`;
        }
      } else {
        link = _.get(formInfo, 'other.termsFile', '');
        if (!link) {
          newText = '';
        } else {
          newText = `<a href="${link}" target="_blank" rel="noopener noreferrer">${TEXTS.terms}</a>`;
        }
      }

      if (index === 0) {
        outputString = text.replace(item, newText);
      } else {
        outputString = outputString.replace(item, newText);
      }
    });

    return outputString;
  };

  return (
    <div className={css.conditions}>
      <label>
        <div className={cn(css.inputWrapper, isError && css.withError)}>
          <input type="checkbox" {...field} checked={field.value} />
          <span></span>
        </div>

        <p dangerouslySetInnerHTML={{ __html: termsText(acceptText) }}></p>
      </label>
      {isError && meta.touched && <ErrorLabel className={css.error} text={meta.error || ''} />}
    </div>
  );
};

const TEXTS = {
  terms: 'תקנון',
  conditions: 'תנאי שימוש',
  privacy: 'מדיניות פרטיות',
};

const CONDITIONS = '{תנאי שימוש}';
const PRIVACY = '{מדיניות פרטיות}';

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE, FORM_NOT_FOUND_ROUTE } from 'client/constants/routes';
import { selectFormInfo } from 'client/redux/formInfo/selectors';
import { useAppSelector } from 'client/hooks';

import shareImg from 'client/assets/share.png';
import favicon from 'client/assets/favicon/favicon.png';

import Main from './pages/Main';
import NotFound from './pages/NotFound';
import { WarningModal } from './common/WarningModal';

import css from './App.module.scss';

const App: React.FC = () => {
  const formInfo = useAppSelector(selectFormInfo);

  React.useEffect(() => {
    removeRootPreloader();

    const root = document.documentElement;

    if (formInfo) {
      root.style.setProperty('--sendButtonColor', formInfo.design.sendButtonColor ?? null);
      root.style.setProperty('--fieldTextColor', formInfo.design.fieldTextColor ?? null);
      root.style.setProperty('--formTitleTextColor', formInfo.design.formTitleTextColor ?? null);
      root.style.setProperty('--linkTextColor', formInfo.design.linkTextColor ?? null);
      root.style.setProperty('--pageBackgroundColor', formInfo.design.pageBackgroundColor ?? null);
    }
  }, []);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);
      setTimeout(() => {
        if (document && document.body) {
          document?.querySelector('.main-preloader')?.remove();
        }
      }, 1500);
    }
  };

  const renderHelmet = () => {
    const seo = {
      title: formInfo?.general.metaTitle || 'seo title',
      description: formInfo?.general.metaDescription || 'seo description',
    };
    const og = {
      title: formInfo?.general.shareTitle || 'share title',
      description: formInfo?.general.shareDescription || 'share description',
      image: formInfo?.general.shareImg || shareImg,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
        <meta property="og:image" content={og.image} />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>
        <Route exact path={FORM_NOT_FOUND_ROUTE}>
          <NotFound />
        </Route>

        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>

      {formInfo && !Boolean(formInfo?.isActive) && (
        <WarningModal isOpen message={formInfo?.formContent.completedActivityText || 'מצטערים, טופס אינו פעיל עוד'} />
      )}
    </div>
  );
};

export default App;

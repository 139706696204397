import * as yup from 'yup';

import { forms, admins } from 'models/index';
import { FORMS_PAGINATE } from 'routes/api/constants';
import { Params2 as PaginationParams, Result2 as PaginationResult } from 'endpoints/pagination';

import { endpoint, EndpointError } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type FormWithAdminInfo = Omit<forms.IModel, 'adminId' | '_id'> & {
  createdBy: Pick<admins.IAdmin, '_id' | 'email' | 'name' | 'role'>;
  _id: string;
};

export type PaginateParams = PaginationParams<FormWithAdminInfo>;
export type PaginateResult = PaginationResult<FormWithAdminInfo>;
export type PaginateError = EndpointError;

export const paginateParamsSchema = yup.object().shape({
  limit: yup.number().min(1).required(VALIDATION_ERROR.REQUIRED),
  offset: yup.number().min(0).required(VALIDATION_ERROR.REQUIRED),
});

export const paginate = endpoint<PaginateParams, PaginateResult, PaginateError>({
  method: 'GET',
  url: () => FORMS_PAGINATE,
});

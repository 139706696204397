export enum ADMIN_ROLE {
  CONTENT_VIEWER = 'CONTENT_VIEWER',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
  PRODUCT_ADMIN = 'PRODUCT_ADMIN',
  DEVELOPER = 'DEVELOPER',
}

export type FORM_MODE = 'create' | 'duplicate' | 'edit';

export const SCOPE = {
  CONTENT: {
    VIEW: 'CONTENT.VIEW',
    EDIT: 'CONTENT.EDIT',
    CREATE: 'CONTENT.CREATE',
    DELETE: 'CONTENT.DELETE',
  },
  USERS: {
    VIEW: 'USERS.VIEW',
    EDIT: 'USERS.EDIT',
    CREATE: 'USERS.CREATE',
    DELETE: 'USERS.DELETE',
  },
} as const;

export const ADMIN_ROLE_LABELS = {
  [ADMIN_ROLE.CONTENT_VIEWER]: 'Analyst',
  [ADMIN_ROLE.CONTENT_EDITOR]: 'Editor',
  [ADMIN_ROLE.PRODUCT_ADMIN]: 'Admin',
  [ADMIN_ROLE.DEVELOPER]: 'Developer',
};

export const PERMISSIONS = {
  [ADMIN_ROLE.CONTENT_VIEWER]: [SCOPE.CONTENT.VIEW],
  [ADMIN_ROLE.CONTENT_EDITOR]: [SCOPE.CONTENT.CREATE, SCOPE.CONTENT.DELETE, SCOPE.CONTENT.EDIT],
  [ADMIN_ROLE.PRODUCT_ADMIN]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
  [ADMIN_ROLE.DEVELOPER]: [...Object.values(SCOPE.CONTENT), ...Object.values(SCOPE.USERS)],
};

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}

export const ADDITIONAL_FIELDS_TYPES = {
  textField: 'textField',
  textArea: 'longTextField',
  date: 'datepicker',
  checkbox: 'checkbox',
  dropDown: 'dropdown',
};

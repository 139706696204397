import { useField } from 'formik';
import cn from 'classnames';
import React from 'react';

import { ClientFieldItem } from 'models/form/interface';

import { ErrorLabel } from '../ErrorLabel';

import css from './Checkbox.module.scss';

interface Props {
  className?: string;
  name: string;
  fieldInfo: ClientFieldItem;
}

export const Checkbox: React.FC<Props> = (props) => {
  const { className = '', name, fieldInfo } = props;
  const [field, meta, helpers] = useField(name);
  const isError = meta.error?.[0] && meta.touched;

  return (
    <label className={cn(css.checkbox, isError && css.withError, className)}>
      <div className={css.main}>
        <input type="checkbox" {...field} checked={field.value} />
        <span className={css.checkMark}></span>
        <span className={css.labelText}>{fieldInfo.label}</span>
      </div>

      {isError && meta.touched && <ErrorLabel className={css.error} text={meta.error?.[0] || ''} />}
    </label>
  );
};

import { admins } from 'models/index';

import { ADMINS_UPDATE } from 'routes/api/constants';

import { createParamsSchema } from './create';
import { endpoint, EndpointError } from '../endpoint';

export type UpdateParams = admins.UpdateParams;
export type UpdateResult = { data: undefined };
export type UpdateError = EndpointError;

export const updateParamsSchema = createParamsSchema;

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'PUT',
  url: (params) => ADMINS_UPDATE.replace(':id', params._id),
});

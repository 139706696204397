import { endpoint, EndpointError } from 'endpoints/endpoint';
import { CSV_EXPORT } from 'routes/api/constants';

export type ExportLogsResult = Buffer;
export type ExportLogsError = EndpointError;

export const exportLogs = endpoint<void, ExportLogsResult, ExportLogsError>({
  method: 'GET',
  url: () => CSV_EXPORT,
});

import yup, { AnySchema } from 'yup';

export enum ERROR {
  REQUIRED = 'REQUIRED',
  TOO_SHORT = 'TOO_SHORT',
  TOO_LONG = 'TOO_LONG',
  TOO_WEAK = 'TOO_WEAK',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_VALUE = 'INVALID_VALUE',
  ONLY_HEBREW = 'ONLY_HEBREW',
  MIN_DIGITS = 'MIN_DIGITS',
  MAX_DIGITS = 'MAX_DIGITS',
  MAX_CHARACTERS = 'MAX_CHARACTERS',
  SLUG_REGEXP_ERROR = 'SLUG_REGEXP_ERROR',
  FILE_IS_TOO_LARGE = 'FILE_IS_TOO_LARGE',
  UNSUPPORTED_FILE_FORMAT = 'UNSUPPORTED_FILE_FORMAT',
  ACCEPT_TEXT_WRONG_STRUCTURE = 'ACCEPT_TEXT_WRONG_STRUCTURE',
  FIELD_FORM_NAME_REGEXP_ERROR = 'FIELD_FORM_NAME_REGEXP_ERROR',
  FIELD_FORM_LINK_REGEXP_ERROR = 'FIELD_FORM_LINK_REGEXP_ERROR',
  FILE_HAS_WRONG_WIDTH_OR_HEIGHT = 'FILE_HAS_WRONG_WIDTH_OR_HEIGHT',
  ONCE_PER_USER_FIELD_MISMATCH = 'ONCE_PER_USER_FIELD_MISMATCH',
  FIELDS_HAVE_ITEMS_WITH_SAME_NAME = 'FIELDS_HAVE_ITEMS_WITH_SAME_NAME',
}

export type RawData = Record<string, ERROR[]>;
export type ReadableData = Record<string, string[]>;
export type ValidationResult = { rawErrors: RawData; errors: ReadableData };
export type ValidationShape<P> = { [K in keyof P]: AnySchema };

export const formatValidation = (error: yup.ValidationError): RawData => {
  return error.inner.reduce((acc: Record<string, ERROR[]>, current) => {
    // if (!(Object.values(ERROR) as string[]).includes(current.message)) {
    //   throw new Error(`Failed to format validation data: ${current.message} is not listed in VALIDATION_ERROR enum`);
    // }

    const path = current.path?.replace('[', '.').replace(']', '');
    const code = current.message as ERROR;

    if (path) {
      if (acc[path] === undefined) acc[path] = [];
      acc[path].push(code);
    }

    return acc;
  }, {});
};

export type ValidationTranslation<FormValues = Record<string, unknown>> = Record<
  keyof FormValues,
  Partial<Record<ERROR, string>>
>;

export const generalErrors = {
  [ERROR.REQUIRED]: 'שדה נדרש',
  [ERROR.TOO_SHORT]: 'Too short',
  [ERROR.TOO_LONG]: 'Too long',
  [ERROR.TOO_WEAK]: 'Too weak',
  [ERROR.INVALID_FORMAT]: 'Invalid format',
  [ERROR.INVALID_VALUE]: 'Invalid value',
  [ERROR.ONLY_HEBREW]: 'Only hebrew characters are supported',
  [ERROR.MIN_DIGITS]: 'Values must be not less than 10',
  [ERROR.MAX_DIGITS]: 'Values must be not more than 10',
  [ERROR.MAX_CHARACTERS]: 'Values mast be not more than 15',
  [ERROR.FIELDS_HAVE_ITEMS_WITH_SAME_NAME]: 'Fields array has items with the same name',
  [ERROR.FIELD_FORM_NAME_REGEXP_ERROR]: 'Must be written in English and with "camelCase"',
  [ERROR.ACCEPT_TEXT_WRONG_STRUCTURE]: 'ישנו שומר מקום מיותר לתקנון או לתנאי השימוש בתוכן ההודעה. נא להסיר את המיותר',
  [ERROR.FILE_HAS_WRONG_WIDTH_OR_HEIGHT]: 'מידות הקובץ שהועלו אינו תקינות. נא להעלות קובץ במידות widthxheight פיקסלים',
  [ERROR.UNSUPPORTED_FILE_FORMAT]: 'הקובץ שהועלה אינו נתמך. יש להעלות קובץ בפורמט jpg או png',
  [ERROR.FIELD_FORM_LINK_REGEXP_ERROR]: 'הקישור חייב להכיל http:// או https://',
  [ERROR.FILE_IS_TOO_LARGE]: 'התמונה שהועלתה גדולה מדי',
  [ERROR.SLUG_REGEXP_ERROR]: 'השדה יכול להכיל רק אותיות קטנות באנגלית, מספרים ואת התווים הבאים: _,-. ללא רווחים',
  [ERROR.ONCE_PER_USER_FIELD_MISMATCH]:
    'Fields array should include one of this fields ("email", "mobilePhone", "personID", "homePhone") it depends on "OncePerUserBy" param in general tab',
} as const;

const errorPriority = (validationError: ERROR) => {
  const errorPriorities = {
    [ERROR.REQUIRED]: 0,
    [ERROR.TOO_SHORT]: 1,
    [ERROR.TOO_LONG]: 1,
    [ERROR.TOO_WEAK]: 2,
    [ERROR.INVALID_FORMAT]: 3,
    [ERROR.INVALID_VALUE]: 4,
    [ERROR.ONLY_HEBREW]: 5,
    [ERROR.SLUG_REGEXP_ERROR]: 5,
    [ERROR.FILE_IS_TOO_LARGE]: 5,
    [ERROR.UNSUPPORTED_FILE_FORMAT]: 5,
    [ERROR.FILE_HAS_WRONG_WIDTH_OR_HEIGHT]: 5,
    [ERROR.ONCE_PER_USER_FIELD_MISMATCH]: 5,
    [ERROR.FIELDS_HAVE_ITEMS_WITH_SAME_NAME]: 5,
    [ERROR.FIELD_FORM_NAME_REGEXP_ERROR]: 5,
    [ERROR.ACCEPT_TEXT_WRONG_STRUCTURE]: 5,
    [ERROR.FIELD_FORM_LINK_REGEXP_ERROR]: 5,
    [ERROR.MAX_CHARACTERS]: 6,
    [ERROR.MIN_DIGITS]: 6,
    [ERROR.MAX_DIGITS]: 6,
  } as const;

  return errorPriorities[validationError] || Number.MAX_VALUE;
};

export const translateValidation = (data: RawData, translations?: ValidationTranslation): ReadableData => {
  const result: Record<string, string[]> = {};

  Object.entries(data).forEach(([key, value]) => {
    const sortedErrors = value.sort((a, b) => errorPriority(a) - errorPriority(b));
    result[key] = sortedErrors.map((v: ERROR) => {
      return translations ? translations[key][v] || generalErrors[v] : generalErrors[v];
    });
  });

  return result;
};

export const validate = async (
  values: Record<string, string | unknown>,
  schema: yup.AnySchema,
  translations?: ValidationTranslation,
): Promise<ValidationResult | null> => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    const rawValidation = formatValidation(error as yup.ValidationError);

    return {
      rawErrors: rawValidation,
      errors: translateValidation(rawValidation, translations),
    };
  }
  return null;
};

import * as yup from 'yup';

import { admins } from 'models/index';
import { ADMIN_ROLE } from 'routes/admin/constants';
import { ADMINS_PAGINATE } from 'routes/api/constants';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR } from '../validation';
import * as Pagination from '../pagination';

type Filter = {
  role?: ADMIN_ROLE[];
};

export type PaginationParams = Pagination.Params<Omit<admins.IAdmin, '_id'>, Filter>;
export type PaginationResult = Pagination.Result<admins.IAdmin>;
export type PaginationError = EndpointError;

export const paginationParamsSchema = yup.object().shape({
  page: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  pageSize: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  search: yup.string(),
  sort: yup.object().shape({
    field: yup.string().oneOf(['role', 'password', 'email', 'phone', 'name'], ERROR.INVALID_VALUE),
    direction: yup.string().oneOf(['asc', 'desc'], ERROR.INVALID_VALUE),
  }),
  filter: yup.object().shape({
    role: yup.string().oneOf(Object.values(ADMIN_ROLE), ERROR.INVALID_VALUE),
  }),
} as ValidationShape<PaginationParams>);

export const paginate = endpoint<PaginationParams, PaginationResult, PaginationError>({
  method: 'GET',
  url: () => ADMINS_PAGINATE,
});

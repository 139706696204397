import * as yup from 'yup';

import { admins } from 'models/index';
import { ADMINS_DELETE } from 'routes/api/constants';

import { endpoint, EndpointError } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type DeleteParams = Pick<admins.IAdmin, '_id'>;
export type DeleteResult = { data: 'OK' };
export type DeleteError = EndpointError;

export const deleteParamsSchema = yup.object().shape({
  _id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const deleteAdmin = endpoint<DeleteParams, DeleteResult, EndpointError>({
  method: 'DELETE',
  url: () => ADMINS_DELETE,
});

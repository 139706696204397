import React, { useState } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import DOMPurify from 'dompurify';
import _ from 'lodash';

import { ClientFieldItem } from 'models/form/interface';

import { ErrorLabel } from '../ErrorLabel';

import css from './TextField.module.scss';

interface Props {
  className?: string;
  name?: string;
  type?: string;
  fieldInfo: ClientFieldItem;
  pattern?: RegExp | null;
}

const TextFieldInput: React.FC<Props> = (props) => {
  const { className = '', name = '', type = '', fieldInfo, pattern } = props;
  const [field, meta, helpers] = useField(name);
  const errorMsg = meta.error?.[0] ?? '';
  const isRequired = _.get(fieldInfo, 'validation.required', false);
  const [isFocused, setIsFocused] = useState(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = DOMPurify.sanitize(e.target.value);

    if (pattern) {
      if (e.target.value === '' || pattern.test(value.toLocaleLowerCase())) {
        helpers.setValue(value);
      }
    } else {
      helpers.setValue(value);
    }
  };

  const fieldProps = {
    ...field,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandler(event);
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onReset: () => {
      console.info('reset');
    },
    onBlur: () => {
      if (!field.value) {
        setIsFocused(false);
      }
    },
  };

  return (
    <div className={classNames(css.container, meta.error && meta.touched && css.isError, className)}>
      <div className={classNames(css.inputWrapper, isFocused && css.focused)}>
        <p className={css.label}>{isRequired ? `*${fieldInfo.label}` : fieldInfo.label || ''}</p>
        <input
          type={type}
          className={classNames(css.formInput)}
          {...fieldProps}
          onChange={onChangeHandler}
          // placeholder={isRequired ? `*${fieldInfo.label}` : fieldInfo.label || ''}
        />
      </div>

      {errorMsg && meta.touched && <ErrorLabel text={errorMsg} />}
    </div>
  );
};

export default TextFieldInput;

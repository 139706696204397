import * as yup from 'yup';

import { admins } from 'models/index';
import { ADMIN_ROLE } from 'routes/admin/constants';
import { ADMINS_CREATE } from 'routes/api/constants';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ERROR as VALIDATION_ERROR } from '../validation';

export type CreateParams = Omit<admins.IAdmin, '_id' | 'password'>;
export type CreateResult = admins.IAdmin;
export type CreateError = EndpointError | { code: ENDPOINT_ERROR.ITEM_ALREADY_EXISTS };

export const createParamsSchema = yup.object().shape({
  role: yup
    .string()
    .oneOf(Object.values(ADMIN_ROLE), VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
  email: yup.string().email(VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
  phone: yup.string(),
  name: yup.string().min(2, VALIDATION_ERROR.INVALID_VALUE),
});

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => ADMINS_CREATE,
});

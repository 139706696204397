import * as React from 'react';

import { detectIE } from 'utils/detect-ie';
import { useResponsive } from 'client/hooks';
import { CAN_USE_DOM } from 'constants/index';

import Icon from '../Icon';
import css from './AccessabilityIcon.module.scss';

const AccessabilityIcon: React.FC = (props) => {
  const [isDesktop] = useResponsive('DESKTOP');
  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('#INDmenu-btn');
  };

  const onButtonClick = () => {
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE()) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE()) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  return (
    <button
      type="button"
      onClick={onButtonClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={css.button}
    >
      <Icon
        type="accessibility"
        width={!isDesktop ? 43 : 34}
        height={!isDesktop ? 43 : 34}
        color={!isDesktop ? '#18655d' : '#e31b23'}
      />
    </button>
  );
};

export default AccessabilityIcon;

import React from 'react';
import cn from 'classnames';

import css from './NotFound.module.scss';

const NotFound: React.FC = () => {
  return (
    <div className={cn(css.notFound)}>
      <div className={css.notFoundTextWrapper}>
        <h1>404</h1>
        <p>{TEXT.title}</p>
      </div>
    </div>
  );
};

const TEXT = {
  title: 'דף זה אינו זמין',
};

export default NotFound;

import * as yup from 'yup';

import { inputField } from 'models/index';
import { INPUT_FIELD_GET } from 'routes/api/constants';

import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';

export type GetParams = void;
export type GetResult = { data: Array<inputField.IModel> };
export type GetError = EndpointError | { code: ENDPOINT_ERROR.ITEM_WITH_SUCH_SLUG_ALREADY_EXISTS };

export const getParamsSchema = yup.object().shape({});

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => INPUT_FIELD_GET,
});

import React, { useMemo } from 'react';

import { ClientFieldItem } from 'models/form/interface';
import { FIELD_INPUT_TYPE, FIELD_TYPE } from 'models/inputField/interface';
import { REG_EXP } from 'constants/index';

import TextField from '../TextField';
import TextArea from '../TextArea';
import Select from '../Select';
import DatePicker from '../DatePicker';
import { Checkbox } from '../Checkbox';

interface Props {
  fieldInfo: ClientFieldItem;
}

const Field: React.FC<Props> = ({ fieldInfo }) => {
  let pattern = null;
  const selectOptions = useMemo(() => fieldInfo.options?.filter((opt) => opt.isVisible), [fieldInfo]);
  if (fieldInfo.validation.onlyHebrewCharacters) pattern = REG_EXP.HEBREW_ONLY_LETTERS;
  if (fieldInfo.validation.onlyNumbers || fieldInfo.validation.homePhone || fieldInfo.validation.mobilePhone)
    pattern = REG_EXP.NUMBERS;

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.TEXT && fieldInfo.type === FIELD_TYPE.REGULAR) {
    return <TextField name={fieldInfo.id.toString()} fieldInfo={fieldInfo} pattern={pattern ? pattern : null} />;
  }

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.SELECT && fieldInfo.type === FIELD_TYPE.REGULAR) {
    return <Select fieldInfo={fieldInfo} name={fieldInfo.id.toString()} options={selectOptions} />;
  }

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.TEXT && fieldInfo.type === FIELD_TYPE.LONG_TEXT_FIELD) {
    return <TextArea name={fieldInfo.id.toString()} fieldInfo={fieldInfo} pattern={pattern ? pattern : null} />;
  }

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.TEXT && fieldInfo.type === FIELD_TYPE.TEXT_FIELD) {
    return <TextField name={fieldInfo.id.toString()} fieldInfo={fieldInfo} pattern={pattern ? pattern : null} />;
  }

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.TEXT && fieldInfo.type === FIELD_TYPE.DATEPICKER) {
    return <DatePicker name={fieldInfo.id.toString()} fieldInfo={fieldInfo} />;
  }
  if (fieldInfo.inputType === FIELD_INPUT_TYPE.SELECT && fieldInfo.type === FIELD_TYPE.DROPDOWN) {
    return <Select fieldInfo={fieldInfo} name={fieldInfo.id.toString()} options={selectOptions} />;
  }

  if (fieldInfo.inputType === FIELD_INPUT_TYPE.BOOLEAN && fieldInfo.type === FIELD_TYPE.CHECKBOX) {
    return <Checkbox name={fieldInfo.id.toString()} fieldInfo={fieldInfo} />;
  }
  return null;
};

export default Field;

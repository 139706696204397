import { ApiResponse } from 'endpoints/endpoint';
import { lead as leadEP } from 'endpoints/index';

import api from '..';

export const lead = api.injectEndpoints({
  endpoints: (builder) => ({
    submit: builder.mutation<ApiResponse<leadEP.CreateResult, leadEP.CreateError>, leadEP.CreateParams>({
      query: (params) => leadEP.create(params).args,
    }),
  }),
  overrideExisting: false,
});

import React from 'react';
import cn from 'classnames';

import { Header, Footer, TopSection, Form, TextsSection } from 'client/components/common';
import css from './Main.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;

  return (
    <div className={cn(css.main, className)}>
      <Header />
      <div className={css.content}>
        <TopSection />
        <TextsSection />
        <Form />
      </div>
      {false && <Footer />}
    </div>
  );
};

export default Main;

import * as yup from 'yup';

import { inputField } from 'models/index';
import { INPUT_FIELD_CREATE } from 'routes/api/constants';
import { FIELD_INPUT_TYPE, FIELD_TYPE, VALIDATION_TYPE } from 'models/inputField/interface';

import { ERROR as VALIDATION_ERROR } from '../validation';
import { endpoint, EndpointError } from '../endpoint';

export type CreateParams = Omit<inputField.IModel, '_id'>;
export type CreateResult = { data: undefined };
export type CreateError = EndpointError;

export const createParamsSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(Object.values(FIELD_TYPE), VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
  inputType: yup
    .string()
    .oneOf(Object.values(FIELD_INPUT_TYPE), VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
  label: yup.string(),
  name: yup.string(),
  options: yup
    .array()
    .of(yup.object().shape({ value: yup.string(), label: yup.string() }))
    .when(['type', 'inputType'], {
      is: (type: FIELD_TYPE, inputType: FIELD_INPUT_TYPE) =>
        type === FIELD_TYPE.DROPDOWN || inputType === FIELD_INPUT_TYPE.SELECT,
      then: yup
        .array()
        .of(yup.object().shape({ value: yup.string(), label: yup.string() }))
        .required(VALIDATION_ERROR.REQUIRED),
    }),
  validationTypes: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().oneOf(Object.values(VALIDATION_TYPE)),
        value: yup
          .mixed()
          .test(
            'value',
            'The "value" must be a "string" or "boolean"',
            (val) => typeof val === 'string' || typeof val === 'boolean',
          )
          .required(VALIDATION_ERROR.REQUIRED),
        editable: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
  validationMessages: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().oneOf(Object.values(VALIDATION_TYPE)),
        value: yup.string().required(VALIDATION_ERROR.REQUIRED),
        editable: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
});

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => INPUT_FIELD_CREATE,
});
